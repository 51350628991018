/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import { Accordion, Stack } from 'react-bootstrap';
import { getTicketUsage } from '../_requests';
import {useAuth} from '../../../../app/modules/auth';

interface TicketOverageRange {
  ticket_price: string,
  tickets_in_range: string,
  range_cost: string,
}

const TicketCountWidget: React.FC = () => {
  const [ticketUsage, setTicketUsage] = useState<number>(0)
  const [ticketAllowance, setTicketAllowance] = useState<number>(0)
  const [additionalTicketCost, setAdditionalTicketCost] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [ticketOverageRanges, setTicketOverageRanges] = useState<Array<TicketOverageRange>>([])
  const {currentUser} = useAuth()
  const companyID = JSON.parse(JSON.stringify(currentUser))?.user.company.id
  let percentage = Math.trunc(ticketUsage / ticketAllowance * 100)
  useEffect(() => {
    const getUsage = async () => {
      getTicketUsage(companyID).then((response) => {
        const {
          total_ticket_count: ticketCount, ticket_allowance: allowance,
          total_ticket_cost: totalCost, ranges
        } = response.data;
        setTicketUsage(ticketCount)
        setTicketAllowance(allowance)
        setAdditionalTicketCost(totalCost)
        setTicketOverageRanges(ranges)
      })
    };
    getUsage().catch((error) => {
      setErrorMessage(`ERROR: ${error}`);
    })
  }, [])
  const inAllowance = percentage > 100 ? Math.trunc(10000/percentage) : percentage
  // set minimum allowance (bar width)
  const minUsageBarWidth = 5
  return (
    <div className='card ticket-count-widget'>
      <div className='card-body mt-3'>
        <h2 className={`card-title fw-bold mt-0 mb-4 d-block text-center`}>
          Ticket Usage
        </h2>
        <div className='pt-1 text-center mb-5'>
          <span className='text-dark fs-1 fw-bold me-2'>
            { ticketAllowance > 0 && 
            <span>{ticketUsage + '/' + ticketAllowance}</span>
            } Tickets
          </span>
        </div>
        <div className='progress-bar' style={{display: 'flex', flexDirection: 'row'}}>
          <div className='bg-success' style={{
            width: inAllowance >= minUsageBarWidth ? inAllowance + '%' : (inAllowance === 0 ? '0%' : minUsageBarWidth + '%')
          }}>
            <p className='ticket-count'>{ticketUsage <= ticketAllowance ? (ticketUsage > 0 ? ticketUsage : '') : ticketAllowance}</p>
          </div>
          <div className='bg-primary bg-opacity-75' style={{width: ticketUsage >= ticketAllowance ? (100 - inAllowance) + '%' : '0%'}}>
            <p className='ticket-count'>{ticketUsage - ticketAllowance > 0 ? ticketUsage - ticketAllowance : ''}</p>
          </div>
          <div className='bg-success bg-opacity-50' style={{width: ticketAllowance === 0 ? '100%' : ticketUsage < ticketAllowance ? (100 - inAllowance) + '%' : '0%'}}>
            <p className='ticket-count'>{ticketUsage - ticketAllowance < 0 ? -(ticketUsage - ticketAllowance) : ''}</p>
          </div>
        </div>
        { ticketUsage > ticketAllowance &&
          <div className='additional-tickets'>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <p className='title'>Additional Tickets:
                      <span className='total-additional-cost'>${additionalTicketCost} </span>
                    </p>
                  </Accordion.Header>
                  <Accordion.Body className=''>
                    <Stack direction='horizontal' gap={3}>
                      {ticketOverageRanges.map((range, index) => {
                        const first = index === 0
                        if (first) {
                          return (
                            <p className='ticket-cost-breakdown-text' key={index}>{range.tickets_in_range} tickets x ${range.ticket_price} = ${range.range_cost}</p>
                          )
                        } else {
                          return (
                            <p className='ticket-cost-breakdown-text' key={index}><span className='plus-sign fw-bold'>+</span> {range.tickets_in_range} tickets x ${range.ticket_price} = ${range.range_cost}</p> 
                          )
                        }
                      })}
                    </Stack>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
          </div>
        }
        {errorMessage &&
          <div className='error-message mt-10'>
            {errorMessage}
          </div>
        }
      </div>
    </div>
  )
}

export {TicketCountWidget}
