import { FC, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { getCompany } from './_requests'
import { getMacros } from '../../modules/macros/_requests'
import { CompanyDetailsModel } from '../../modules/accounts/components/settings/SettingsModel'
import { finalizeGorgiasAuth } from '../dashboard/_requests'
import { UserModel } from '../../modules/auth/core/_models'
import { toAbsoluteUrl } from '../../../_metronic/helpers/AssetHelpers'
import { randomString } from '../../utils';
import { useAuth } from '../../modules/auth';
import { getTicketUsage } from '../../../_metronic/partials/widgets/_requests';


const CompanyPage: FC = () => {
  const {companyID} = useParams();
  const [searchParams, _] = useSearchParams();
  const [company, setCompany] = useState<CompanyDetailsModel | undefined>(undefined);
  const [team, setTeamMembers] = useState<Array<UserModel>>([]);
  const [macros, setMacros] = useState<Array<string>>([]);
  const [companyErrorMessage, setCompanyErrorMessage] = useState<string | undefined>();
  const [macrosErrorMessage, setMacrosErrorMessage] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [ticketAllowance, setTicketAllowance] = useState<number>(0);
  const [ticketUsage, setTicketUsage] = useState<number>(0);
  const [gorgiasIntegrated, setGorgiasIntegrated] = useState<boolean>(true);
  const {currentUser} = useAuth();
  // So ugly... but not sure how else to go about it
  // https://stackoverflow.com/questions/17546953/cant-access-object-property-even-though-it-shows-up-in-a-console-log
  const user = JSON.parse(JSON.stringify(currentUser)).user;
  const isAgent = user.is_agent;

  const updateTicketUsage = async () => {
    getTicketUsage(Number(companyID!)).then((response) => {
      const {total_ticket_count: ticketCount, ticket_allowance: allowance} = response.data;
      setTicketUsage(ticketCount);
      setTicketAllowance(allowance);
      setGorgiasIntegrated(true);
    }).catch((error) => {
      // For now, assuming it's a permissions issue.
      //TODO: check for specific error/error message
      setGorgiasIntegrated(false);
    });
  }

  useEffect(() => {
    const getCompanyDetails = async () => {
      getCompany(companyID!).then((response) => {
        const {company, team_members: members} = response.data;
        let teamMembers: Array<UserModel> = [];
        for (let i=0; i<members.length; i++) {
          teamMembers.push(new UserModel(members[i]));
        }
        setTeamMembers(teamMembers);
        setCompany(new CompanyDetailsModel(company));
      }).catch((error) => {
        setCompanyErrorMessage('ERROR: ' + error);
      });
      getMacros(companyID).then((response) => {
        const {macros} = response.data;
        setMacros(macros);
      }).catch((error) => {
        setMacrosErrorMessage('ERROR: ' + error);
      });
    };
    getCompanyDetails();
    // Handle Admin Gorgias Auth setup
    const gorgiasIntegration = async () => {
      const gorgiasCode = searchParams.get("code");
      const gorgiasOAuthState = searchParams.get("state");
      if (gorgiasOAuthState && gorgiasCode) {
        // check if state is the same
        if (gorgiasOAuthState !== localStorage.getItem('gorgias_oauth_state')) {
          throw new Error("States don't match");
        } else {
          // finish on serverside
          setSubmitting(true);
          console.log(':::finalizing gorgias auth:::');
          console.log(gorgiasCode);
          console.log(user.company.id);
          const response = await finalizeGorgiasAuth(gorgiasCode, user.company.id);
          console.log('RESPONSE:::', response);
          setSubmitting(false);
          await updateTicketUsage();
        }
      }
    };
    gorgiasIntegration();
    updateTicketUsage();
  }, []);
  const phone = (
    company?.phoneNumber?.slice(0,3) + '-' +
    company?.phoneNumber?.slice(3,6) + '-' +
    company?.phoneNumber?.slice(6,10)
  )
  // TODO: move these to a separate file somewhere
  const clientID = process.env.REACT_APP_GORGIAS_CLIENT_ID;
  const scope = 'account:read integrations:read integrations:write tickets:read tickets:write users:read users:write statistics:read tags:read tags:write';
  const nonce = randomString(10);
  const redirectURI = (
    isAgent ? process.env.REACT_APP_GORGIAS_AGENT_REDIRECT_URI + `/${companyID}`
    : process.env.REACT_APP_GORGIAS_REDIRECT_URI
  );

  return (
    <div className='admin-company-page'>
      <h1>Company Details</h1>
      { company && !gorgiasIntegrated &&
        <button
          type='submit'
          className='btn btn-lg btn-primary mt-0 mb-8'
          onClick={async ()=>{
            setSubmitting(true);
            // If the URL ends with a slash, remove it
            let gorgiasURL = company?.thirdPartyIntegrations?.gorgiasURL?.replace(/\/$/, '');
            if (!gorgiasURL) {
              setErrorMessage('Gorgias URL is missing from this account.')
              return setSubmitting(false);
            }
            const CSRFState = randomString(20);
            // save state and check on redirect back to dashboard (prevents CSRF attacks)
            localStorage.setItem('gorgias_oauth_state', CSRFState)
            // Gorgias preview for when Gorgias tests updated app version
            const gorgiasPreview = process.env.REACT_APP_GORGIAS_VERSION_PREVIEW
            window.location.href = (
              `${gorgiasURL}/oauth/authorize?response_type=code&client_id=${clientID}&state=${CSRFState}&scope=${scope}&nonce=${nonce}&redirect_uri=${redirectURI}&preview=${gorgiasPreview}`
            );
          }}
        >
          <span className='indicator-label'>
            { !submitting &&
              <span className='indicator-label'>Please Connect Gorgias 🙏🏻</span>
            }
            { submitting &&
              <img alt='Loading' className='loading-icon' src={toAbsoluteUrl(`/media/gifs/loading.gif`)} />
            }
          </span>
        </button>
      }
      {errorMessage &&
        <div className='error-message mt-10'>
          {errorMessage}
        </div>
      }

      <div className='card-body'>
        {companyErrorMessage ? (
          <div className='card-body'>
            <div className='error-message mt-10'>
              {companyErrorMessage}
            </div>
          </div>
        ) : (
          <>
            <Row className='mb-4'>
              <Col>
                <h2>{company?.name}</h2>
              </Col>
            </Row>
            <Row>
              <Col>
              <h3 className='fw-bold mt-0 mb-8'>
                {ticketUsage + '/' + ticketAllowance} Tickets Used
              </h3>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>ID:</p>
                  </Col>
                  <Col>
                    <p>{company?.id}</p>
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Website:</p>
                  </Col>
                  <Col>
                    <p>{company?.website}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Phone Number:</p>
                  </Col>
                  <Col>
                    { company?.phoneNumber ? (
                      <a href={`tel:${phone}`}>
                        <p>{phone}</p>
                      </a>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Timezone:</p>
                  </Col>
                  <Col>
                    <p>{company?.timezone}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Plan:</p>
                  </Col>
                  <Col>
                    <p>{company?.plan?.name}</p>
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Payment Schedule:</p>
                  </Col>
                  <Col>
                    <p>{company?.plan?.schedule[0].toUpperCase()}{company?.plan?.schedule.slice(1)}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Stripe Customer:</p>
                  </Col>
                  <Col>
                    <p>{company?.stripeCustomerID}</p>
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Stripe Subscription:</p>
                  </Col>
                  <Col>
                    <p>{company?.stripeSubscriptionID}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className='solid'></hr>
            <Row>
              <Col>
                <h3>Social</h3>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Instagram:</p>
                  </Col>
                  <Col>
                    {company?.instagramURL ? (
                      <p>{company?.instagramURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Facebook:</p>
                  </Col>
                  <Col>
                    {company?.facebookURL ? (
                      <p>{company?.facebookURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Twitter:</p>
                  </Col>
                  <Col>
                    {company?.twitterURL ? (
                      <p>{company?.twitterURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
              </Col>
            </Row>
            <hr className='solid'></hr>
            <Row>
              <Col>
                <h3>Links</h3>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>FAQ:</p>
                  </Col>
                  <Col>
                    {company?.faqURL ? (
                      <a href={`https://${company.faqURL}`} target='_blank' rel="noreferrer">
                        <p>{company.faqURL}</p>
                      </a>
                    ) :
                      <p>N/A</p>
                    }
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Blog:</p>
                  </Col>
                  <Col>
                    {company?.blogURL ? (
                      <a href={`https://${company.blogURL}`} target='_blank' rel="noreferrer">
                        <p>{company.blogURL}</p>
                      </a>
                    ) :
                      <p>N/A</p>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Newsletter Signup:</p>
                  </Col>
                  <Col>
                    {company?.newsletterSignupURL ? (
                      <a href={`https://${company.newsletterSignupURL}`} target='_blank' rel="noreferrer">
                        <p>{company.newsletterSignupURL}</p>
                      </a>
                    ) :
                      <p>N/A</p>
                    }
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
              </Col>
            </Row>
            <hr className='solid'></hr>
            <Row>
              <Col>
                <h3>Integrations</h3>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Gorgias:</p>
                  </Col>
                  <Col>
                    {company?.thirdPartyIntegrations?.gorgiasURL ? (
                      <p>{company?.thirdPartyIntegrations?.gorgiasURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Shopify:</p>
                  </Col>
                  <Col>
                    {company?.thirdPartyIntegrations?.shopifyURL ? (
                      <p>{company?.thirdPartyIntegrations?.shopifyURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Shipstation:</p>
                  </Col>
                  <Col>
                    {company?.thirdPartyIntegrations?.shipstationURL ? (
                      <p>{company?.thirdPartyIntegrations?.shipstationURL}</p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
              </Col>
            </Row>
          </>
        )}
      </div>
      <h2 className='mt-10 mb-8'>Team Members</h2>
      {team.map((member, index) => {
        return (
          <Link to={`/admin/users/${member.id!.toString()}`} key={index}>
            <div className='card-body team-member-item'>
              <Row>
                <Col className='item-column' sm={1}>
                  <p>{member.id}</p>
                </Col>
                <Col  className='item-column'>
                  <p>{member.firstName} {member.lastName}</p>
                </Col>
                <Col  className='item-column'>
                  <p>{member.email}</p>
                </Col>
                <Col  className='item-column'>
                  <p className={`member-owner member-owner-${member.isOwner ? 'owner' : 'member'}`}>{member.isOwner ? 'Owner' : 'Member'}</p>
                </Col>
                <Col className='item-column arrow-column' sm={1}>
                  <img alt='Right Arrow' className='arrow-icon' src={toAbsoluteUrl('/media/icons/custom/gray-right-arrow-rounded.png')} />
                </Col>
              </Row>
            </div>
          </Link>
        )
      })}
      {(macros.length > 0 || macrosErrorMessage) &&
        <h2 className='mt-10 mb-8'>Macros Snippets</h2>
      }
      {macrosErrorMessage ? (
        <div className='card-body'>
          <div className='error-message mt-10'>
            {macrosErrorMessage}
          </div>
        </div>   
      ) : (
        <>
          {macros.map((macro, index) => {
            return (
              <CompanyMacrosSnippet macro={macro} key={index} />
            )
          })}
        </>
      )}
    </div>
  )
}

interface MacroSnippetProps{
  macro: string
}

const CompanyMacrosSnippet: FC<MacroSnippetProps> = (props) => {
  const macro = props.macro;
  const [copied, setCopied] = useState<boolean>(false);
  const brRegex = /<br\s*[\/]?>/gi;
  const copyText = async (macro: string) => {
    const updated_macro = macro.replace(brRegex, '\r\n');
    navigator.clipboard.writeText(updated_macro).then(() => {
      setCopied(true);
      setTimeout(()=> {
        setCopied(false);
      }, 3000);
    })
  }

  return (
    <div className='macros-snippet'>
      {copied ? (
        <p className='copied-text fw-bold'>Copied!</p>
      ) : (
        <img onClick={() => {copyText(macro)}} alt='Copy Text' className='copy-icon' src={toAbsoluteUrl('/media/icons/duotune/general/gen054.svg')} />
      )}
      <p dangerouslySetInnerHTML={{__html: macro}}></p>
    </div>
  )
}

export {CompanyPage, CompanyMacrosSnippet}
