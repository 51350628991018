import {FC, useEffect, useState} from 'react'
import {Stack, Row, Col} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {getUser} from './_requests'
import {UserModel} from '../../modules/auth/core/_models'


const UserPage: FC = () => {
  const {userID} = useParams();
  const [member, setMember] = useState<UserModel | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  useEffect(() => {
    const getUserDetails = async () => {
      getUser(userID!).then((response) => {
        const {user} = response.data;
        setMember(new UserModel(user));
      })
    }
    getUserDetails().catch((error) => {
      setErrorMessage('ERROR: ' + error);
    })
  }, []);
  return (
    <div className='admin-user-page'>
      <h1>Member Details</h1>
      {!member && errorMessage ? (
        <div className='card-body'>
          <div className='error-message mt-10'>
            {errorMessage}
          </div>
        </div>
      ) : (
        <>
          <div className='card-body'>
            <Row>
              <Col>
              <Stack className='username-stack' direction='horizontal' gap={5}>
                <h2>{member?.firstName} {member?.lastName}</h2>
                <p className={`member-owner member-owner-${member?.isOwner ? 'owner' : 'member'}`}>{member?.isOwner ? 'Owner' : 'Member'}</p>
              </Stack>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>ID:</p>
                  </Col>
                  <Col>
                    <p>{member?.id}</p>
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Company ID:</p>
                  </Col>
                  <Col>
                    <a style={{textDecoration: 'none', color: '#00AEE6', fontWeight: '600'}} href={`/admin/companies/${member?.companyID}`}>{member?.companyID}</a>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Company Name:</p>
                  </Col>
                  <Col>
                    <a style={{textDecoration: 'none', color: '#00AEE6', fontWeight: '600'}} href={`/admin/companies/${member?.companyID}`}>{member?.companyName}</a>
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Email:</p>
                  </Col>
                  <Col>
                    <p>{member?.email}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Job Title:</p>
                  </Col>
                  <Col>
                    <p>{member?.jobTitle || 'N/A'}</p>
                  </Col>
                </Row>
              </Col>
              <Col className='mb-5' md={6}>
                <Row>
                  <Col sm={6}>
                    <p className='fw-bold'>Google Login:</p>
                  </Col>
                  <Col>
                    <p>{member?.googleID ? 'True' : ' False'}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {member?.invitation &&
            <div className='card-body'>
              <Row className='mb-8'>
                <Col>
                  <h2 className='invitation-header'>Invitation</h2>
                </Col>
              </Row>
              <Row>
                <Col className='mb-5' md={6}>
                  <Row>
                    <Col sm={6}>
                      <p className='fw-bold'>Status:</p>
                    </Col>
                    <Col>
                      <div className={`member-status member-status-${member.invitation.status === 'accepted' ? 'active' : member.invitation.status}`}>
                        {member.invitation.status === 'accepted' ? 'Active' : member.invitation.status}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className='mb-5' md={6}>
                  <Row>
                    <Col sm={6}>
                      <p className='fw-bold'>Last Sent:</p>
                    </Col>
                    <Col>
                      <p>{member?.invitation?.last_sent}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
        </>
      )}
    </div>
  )
}

export {UserPage}
