import {FC} from 'react'

const Step5: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>You're All Set!</h2>
      </div>

      <div className='mb-0'>
        <div className='fs-2 text-gray-600 mb-5'>
          Thank you for signing up for Dedication Agents. Our team of agents is going 
          through all your information and will contact you soon to finalize your setup. 
          Feel free to check out the dashboard in the meantime.
        </div>
      </div>
    </div>
  )
}

export {Step5}
