export interface AuthModel {
  api_token: string
  refreshToken?: string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

export interface UserInvitation {
  id: number
  status: string
  last_sent: string
}

export interface CompanyModelInterface {
  id: number
  name: string
}

export interface UserModelInterface {
  // helps serialization from snake case to camel case
  id: number
  email: string
  first_name: string
  last_name: string
  is_agent: boolean
  is_owner: boolean
  invitation: UserInvitation
  google_id?: string
  company?: CompanyModelInterface
  job_title?: string
}

export class UserModel {
  id: number
  email: string
  firstName: string
  lastName: string
  googleID?: string
  companyID?: number
  companyName?: string
  isAgent: boolean
  isOwner: boolean
  invitation: UserInvitation
  jobTitle?: string

  constructor(user: UserModelInterface) {
    // helps serialization from snake case to camel case
    this.id = user.id;
    this.email = user.email;
    this.firstName = user.first_name;
    this.lastName = user.last_name;
    this.googleID = user.google_id;
    this.companyID = user.company?.id;
    this.companyName = user.company?.name;
    this.isAgent = user.is_agent;
    this.isOwner = user.is_owner;
    this.invitation = user.invitation;
    this.jobTitle = user.job_title;
  }
}
